export default {
  pending: {
    class: 'bg-warning text-warning',
    label: 'Pending',
    name: 'pending',
  },
  created: {
    class: 'bg-warning text-warning',
    label: 'Created',
    name: 'created',
  },
  completed: {
    class: 'bg-success text-success',
    label: 'Completed',
    name: 'completed',
  },
  processed: {
    class: 'bg-success text-success',
    label: 'Processed',
    name: 'processed',
  },
  failed: {
    class: 'bg-danger text-danger',
    label: 'Failed',
    name: 'failed',
  },
  cancelled: {
    class: 'bg-danger text-danger',
    label: 'Cancelled',
    name: 'cancelled',
  },
};
