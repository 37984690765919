<template>
  <div class="card" id="orderChangeRequestSection">
    <div class="card-header">
      <h3 class="card-title">Change Requests</h3>
      <div v-if="can(uiPermissions.ORDER_CHANGE_REQUESTS_CREATE)" class="card-options gap-12">
        <button
          v-b-tooltip="'Refresh'"
          ignore-disable
          class="btn btn-outline-primary rounded"
          @click.prevent="refresh">
          <i class="fe fe-refresh-ccw"></i>
        </button>
        <button
          force-disable
          ignore-disable
          class="btn btn-primary"
          data-test="btn-log-change-request"
          @click.prevent="showChangeRequestModal = true"
        >
          <i class="fe fe-flag mr-2"></i>
          Log change request
        </button>
      </div>
    </div>

    <order-change-requests-table
      source="component"
      :items="items"
      :page="page"
      :totalPages="meta.totalPages"
      :loading="listLoading"
      @goToPage="goToPage"
    />

    <order-change-request-modal
      v-if="can(uiPermissions.ORDER_CHANGE_REQUESTS_CREATE)"
      :show.sync="showChangeRequestModal"
      :delivery-date="deliveryDate"
      :delivery-time="deliveryTime"
      :delivery-days="deliveryDays"
      @submit="handleSubmit"
    />
  </div>
</template>

<script>
import OrderChangeRequestStatus from '@/assets/enums/OrderChangeRequestStatus';
import OrderChangeRequestModal from '@/components/OrderChangeRequestModal';
import OrderChangeRequestsTable from '@/components/OrderChangeRequestsTable.vue';
import list from '@/mixins/list';
import {orderChangeRequest} from '@/services';
import submitting from '@hellochef/shared-js/mixins/submitting';

export default {
  components: {
    OrderChangeRequestsTable,
    OrderChangeRequestModal,
  },
  mixins: [list, submitting],
  props: {
    deliveryDate: {
      type: String,
      required: true,
    },
    deliveryTime: {
      type: Object,
      required: true,
    },
    deliveryDays: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      OrderChangeRequestStatus,
      showChangeRequestModal: false,
    };
  },

  methods: {
    fetchData(page) {
      return orderChangeRequest.getByParameters({
        limit: this.limit,
        page,
        order_id: this.$route.params.id,
        with: 'createdBy,updatedBy',
      });
    },
    transformData(data) {
      data.items.map(it => {
        it.loading = false;
        return it;
      });
      return data;
    },
    handleSubmit() {
      this.refresh();
      this.showChangeRequestModal = false;
    },
  },
};
</script>
