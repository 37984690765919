<template>
  <b-modal
    v-model="safeShow"
    title="Add transaction"
    id="modal-add-transaction"
    @show="handleOnShow"
    @hide="handleOnHide">
    <template slot="modal-header-close"><wbr/></template>

    <template slot="modal-footer">
      <button
        class="btn btn-primary w-9"
        type="submit"
        form="transactionForm"
        :disabled="loading">
        Submit
      </button>
    </template>

    <div
      v-show="invalid"
      class="alert alert-icon alert-danger alert-dismissible"
      data-test="label-invalid-message">
      <button
        type="button"
        class="close"
        @click="invalid = false">
      </button>
      <i class="fe fe-alert-triangle mr-2" aria-hidden="true"></i>
      {{invalidMessage || 'Some values are still missing or invalid.'}}
    </div>

    <form
      ref="transactionForm"
      id="transactionForm"
      @submit.prevent="submit">
      <dimmer :active="loading">
        <div class="row">
          <div class="col-4">
            <div class="form-group">
              <label class="form-label">Amount</label>
              <div class="input-group">
                <input
                  v-model="form.total"
                  ref="totalInputRef"
                  type="number"
                  data-test="input-amount"
                  step="any"
                  min="0"
                  required
                  class="form-control"/>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-8">
            <div class="form-group">
              <label class="form-label">Payment reference</label>
              <div class="input-group">
                <input
                  v-model="form.reference"
                  type="text"
                  data-test="input-reference"
                  required
                  class="form-control"
                />
              </div>
            </div>
          </div>
        </div>
      </dimmer>
    </form>
  </b-modal>
</template>

<script>

import {payments} from '@/services';
import {captureException} from '@sentry/vue';

export default {
  name: 'AddTransactionModal',
  props: [
    'orderId',
    'show',
  ],
  data() {
    return {
      form: {
        total: '',
        reference: '',
      },
      loading: false,
      invalid: false,
      invalidMessage: '',
    };
  },
  computed: {
    safeShow: {
      get() { return this.show; },
      set(newValue) {
        this.$emit('update:show', newValue);
      },
    },
  },
  methods: {
    async handleOnShow() {
      setTimeout(() => {
        this.$refs.totalInputRef.focus();
      });
    },
    async submit() {
      try {
        const confirm = window.confirm(`Are you sure you want to submit this transaction amount AED ${this.form.total}?`);
        if (!confirm) {
          return;
        }

        this.loading = true;
        const {item} = await payments.post({
          order_id: this.orderId,
          total: this.form.total,
          transaction_ref: this.form.reference,
        });
        this.$emit('onSuccess', item.payment);
        this.safeShow = false;
      }
      catch (error) {
        captureException(error);
        this.invalid = true;

        if (error.response?.data.message) {
          this.invalidMessage = error.response.data.message;
        }
      }
      finally {
        this.loading = false;
      }
    },
    handleOnHide() {
      this.form = {
        total: '',
        reference: '',
      };
    },
  },
};

</script>

<style>
.modal-footer {
  justify-content: end;
}
</style>
