<template>
  <div class="card recipe-selection-card mb-0 border-0">
    <div v-if="withHeader" class="card-header">
      <h3 class="card-title" data-test="label-selection-header">{{caption}} ({{max ? `${totalSelected} of ${max}` : totalSelected}} selected)</h3>
      <div v-if="filter" class="card-options">
        <input
          v-model="query"
          type="text"
          class="form-control"
          placeholder="Filter"/>
      </div>
    </div>
    <div style="overflow-y: scroll;">
      <table class="table table-vcenter text-nowrap card-table">
        <tbody>
          <template v-for="item in shownItems">
            <tr :key="item.recipe.id">
              <td :class="[{'row-selected': quantity(item.recipe.id) > 0}, {'has-swap': !!(item.swaps && item.swaps.length)}]" class="recipe-selector-container">
                <recipe-selector
                  v-if="!readOnly"
                  :data-test="`recipe-selector-${item.recipe.id}`"
                  class="order-recipe-selector"
                  :quantity="quantity(item.recipe.id)"
                  :max="max"
                  :disabled="totalSelected >= max || isSwapSelected(item)"
                  :name="item.recipe.name"
                  :number="item.recipe.id"
                  :price="getGourmetPrice(item.recipe)"
                  :has-swaps="!!(item.swaps && item.swaps.length)"
                  :delivery-split="deliverySplit(item.id)"
                  @change="quantity => setRecipeQuantity({id: item.recipe.id, quantity})"
                />
                <div v-else class="readonly-container">
                  <p>
                    {{item.recipe.id}}. {{item.recipe.name}}
                  </p>
                </div>
              </td>
            </tr>
            <tr v-for="swap in item.swaps" :key="`swap-${swap.recipe.id}`">
              <td :class="{'row-selected': quantity(swap.recipe.id) > 0}" class="recipe-selector-container">
                <recipe-selector
                  v-if="!readOnly"
                  has-swaps
                  highlight-swap
                  :data-test="`recipe-selector-swap-${item.recipe.id}`"
                  class="order-recipe-selector swap-recipe-selector"
                  :quantity="quantity(swap.recipe.id)"
                  :max="max"
                  :disabled="totalSelected >= max || isSwapSelected(swap)"
                  :name="swap.recipe.name"
                  :number="swap.recipe.id"
                  :delivery-split="deliverySplit(item.id)"
                  @change="quantity => setRecipeQuantity({id: swap.recipe.id, quantity})"
                />
                <div v-else class="readonly-container">
                  <p>
                    {{swap.recipe.id}}. {{swap.recipe.name}}
                  </p>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import RecipeSelector from '@/components/shared/recipe-selector/index.vue';

export default {
  name: 'RecipeSelectionCard',
  components: {
    RecipeSelector,
  },
  props: {
    caption: String,
    disabled: {default: () => false, type: Boolean},
    filter: {default: true},
    items: Array,
    max: Number,
    readOnly: Boolean,
    selections: {
      type: Array,
      default: () => [],
    },
    totalSelected: Number,
    withHeader: {
      type: Boolean,
      default: true,
    },
    numberOfPeople: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      query: '',
      sortedItems: [],
    };
  },
  computed: {
    isSwapSelected() {
      return selected => {
        const allRecipes = [...this.items, ...this.items.flatMap(recipe => recipe.swaps || [])];
        const populateSelections = this.selections.filter(selection => selection.quantity).map(selection => allRecipes.find(({recipe}) => recipe.id === selection.id));
        return !!populateSelections.find(selection => selection.number === selected.number && selection.recipe.id !== selected.recipe.id);
      };
    },
    shownItems() {
      return this.sortedItems.filter(item => this.query === '' || item.recipe.name.split(' ').join('').toLowerCase().includes(this.query.split(' ').join('').toLowerCase()));
    },
  },
  watch: {
    items: {
      handler() {
        this.sortedItems = this.sortRecipesBySelections();
      },
      immediate: true,
    },
  },
  methods: {
    setRecipeQuantity(selection) {
      this.$emit('input', selection);
    },
    getGourmetPrice(recipe) {
      if (!recipe[`gourmetSurchargeFor${this.numberOfPeople}`]) {
        return 0;
      }
      return recipe[`gourmetSurchargeFor${this.numberOfPeople}`];
    },
    sortRecipesBySelections() {
      // sort by not mutating the original array
      const toSortItems = structuredClone(this.items);
      return toSortItems.sort((a, b) => {
        const aSelected = this.selections.find(selected => selected.id === a.recipe.id);
        const bSelected = this.selections.find(selected => selected.id === b.recipe.id);
        if (aSelected && bSelected) {
          return aSelected.quantity > bSelected.quantity ? -1 : 1;
        }
        if (aSelected) {
          return -1;
        }
        if (bSelected) {
          return 1;
        }
        return 0;
      });
    },
    quantity(id) {
      return this.selections.find(selected => selected.id === id)?.quantity || 0;
    },
    deliverySplit(id) {
      return this.selections.find(selected => selected.id === id)?.delivery_split_number;
    },
  },
};

</script>

<style scoped>
.recipe-selection-card {
  max-height: 560px;
  border-right: 1px solid #E0E5EC !important;
}

.has-swap,.swap-recipe-selector {
  >>> .truncate-recipe {
    width: 370px;
  }
}

.recipe-selector-container {
  padding: 0 !important;

  border-bottom: 1px solid #e9ecef;

  &:hover {
    background-color: #E0E5EC;
  }
}

.row-selected {
  background-color: #d2ecb8;

  &:hover {
    background-color: #d2ecb8;
  }
}
</style>
