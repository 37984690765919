<template>
  <div class="container">
    <edit-header
      entity="order"
      :error.sync="error"
      :invalid.sync="invalid"
      :submitted.sync="submitted"
      :invalidMessage.sync="invalidMessage"
      :isNew="isNew">
      <template v-slot:options>
        <div class="d-flex align-items-center gap-12">
          <button
            v-if="can(uiPermissions.COMPLAINTS_CREATE)"
            class="btn btn-primary"
            @click.prevent="openComplaintsModal()">
            <i class="fe fe-flag mr-2"></i>
            Log complaint
          </button>
        </div>
      </template>
    </edit-header>

    <div v-if="successComplaint" class="alert alert-icon alert-success alert-dismissible">
      <button
        type="button"
        class="close"
        @click="successComplaint = false"></button>
      <i class="fe fe-check mr-2" aria-hidden="true"></i> Complaint successfully created.
    </div>
    <div v-if="sendInvoiceSubmitted" class="alert alert-icon alert-success alert-dismissible">
      <button
        type="button"
        class="close"
        @click="sendInvoiceSubmitted = false"></button>
      <i class="fe fe-check mr-2" aria-hidden="true"></i> An invoice has been emailed to the customer.
    </div>
    <div v-if="manualPaymentSubmitted" class="alert alert-icon alert-success alert-dismissible">
      <button
        type="button"
        class="close"
        @click="manualPaymentSubmitted = false"></button>
      <i class="fe fe-check mr-2" aria-hidden="true"></i> Payment made for the order
    </div>
    <div v-if="paymentRefundedSubmitted" class="alert alert-icon alert-success alert-dismissible">
      <button
        type="button"
        class="close"
        @click="paymentRefundedSubmitted = false"></button>
      <i class="fe fe-check mr-2" aria-hidden="true"></i> Transaction successfully refunded
    </div>
    <div v-if="paymentVoidedSubmitted" class="alert alert-icon alert-success alert-dismissible">
      <button
        type="button"
        class="close"
        @click="paymentVoidedSubmitted = false"></button>
      <i class="fe fe-check mr-2" aria-hidden="true"></i> Transaction successfully voided
    </div>
    <div v-if="paymentVoidedFailed" class="alert alert-icon alert-danger alert-dismissible">
      <button
        type="button"
        class="close"
        @click="paymentVoidedFailed = false"></button>
      <i class="fe fe-alert-triangle mr-2" aria-hidden="true"></i> Transaction can't be voided - voiding hours expired.
    </div>
    <div v-if="sendInvoiceError" class="alert alert-icon alert-danger alert-dismissible">
      <button
        type="button"
        class="close"
        @click="sendInvoiceError = false"></button>
      <i class="fe fe-alert-triangle mr-2" aria-hidden="true"></i> Unable to email invoice.
    </div>
    <div
      v-if="!!repeatOffenderMessage"
      class="alert alert-icon alert-danger alert-dismissible pulse pulse-limit"
      data-test="label-repeat-offender">
      <i class="fe fe-alert-triangle mr-2" aria-hidden="true"></i>
      {{repeatOffenderMessage}}
    </div>

    <form
      v-disable-all="disableForm"
      ref="form"
      class="validation"
      novalidate
      @submit.prevent="submit">
      <div class="row row-cards row-deck">
        <div class="col-lg-4">
          <div class="card">
            <div class="card-header">
              <h3 v-if="isNew" class="card-title">Order</h3>
              <h3 v-else class="card-title">Order #{{item.id}}</h3>
              <div class="card-options">
                <div
                  v-if="item.complaints.length"
                  class="badge badge-danger small align-self-center cursor-pointer"
                  @click="scrollToComplaints">
                  {{item.complaints.length}} complaints
                </div>
                <div class="item-action dropdown ml-auto">
                  <a
                    tabindex="0"
                    data-toggle="dropdown"
                    class="icon"><i class="fe fe-more-vertical"></i></a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <button
                      type="button"
                      class="dropdown-item"
                      @click="manual(item)">
                      <i class="dropdown-icon fe fe-external-link"></i> Manual payment
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="form-row">
                <div class="col-6">
                  <div v-if="isNew" class="form-group">
                    <label class="form-label">User</label>
                    <user-selector v-model="customer" :required="true"></user-selector>
                  </div>
                  <div class="form-group">
                    <label class="form-label">Subscription</label>
                    <select
                      v-if="isNew"
                      v-model="subscription"
                      class="form-control custom-select"
                      required>
                      <option
                        v-if="!customer"
                        :value="null"
                        disabled>
                        Select a customer first
                      </option>
                      <template v-else>
                        <option :value="null" disabled>Select subscription</option>
                        <option
                          v-for="value in customer.subscriptions"
                          :key="value.id"
                          :disabled="value.status !== 'active'"
                          :value="value">
                          ({{value.status}}) #{{value.id}}, {{`${value.product.menu.name} box for
                                                    ${value.product.number_of_people} persons ${value.product.number_of_recipes} recipes`}}
                        </option>
                      </template>
                    </select>
                    <router-link v-else :to="`/subscriptions/${subscription.id}`">
                      #{{subscription.id}},
                      {{`${subscription.product.menu.name} box for
                                            ${subscription.product.number_of_people} persons ${subscription.product.number_of_recipes}
                      recipes`}}
                    </router-link>
                  </div>
                  <div class="form-group">
                    <product-selector
                      v-if="filterProducts"
                      :selected="customProduct ? customProduct.id : null"
                      :products="filterProducts"
                      @change="product => customProduct = product"/>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label class="form-label">Status</label>
                    <select
                      v-model="item.status"
                      class="form-control custom-select"
                      ignore-disable
                      force-disable
                      :disabled="disableForm"
                      required>
                      <option value="" disabled>Select status</option>
                      <option
                        v-for="(value, key) in OrderStatuses"
                        :key="value"
                        :value="value">
                        {{key}}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label class="form-label">Payment</label>
                    <select
                      v-if="isNew"
                      v-model="item.paymentMethod"
                      class="form-control custom-select"
                      required>
                      <option value="" disabled>Select method</option>
                      <option
                        v-for="(value, key) in PaymentMethods"
                        :key="value"
                        :value="value">
                        {{key}}
                      </option>
                    </select>
                    <div v-else>
                      <input
                        :value="item.paymentMethod || 'N/A'"
                        type="text"
                        class="form-control-plaintext"
                        readonly>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">

                <div class="col-12">
                  <div class="form-group">
                    <label class="form-label">Admin Note</label>
                    <textarea v-model="item.note" class="form-control"></textarea>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div v-if="!isNew" class="col-lg-4">
          <user-card :value="customer"></user-card>
        </div>
        <div :class="isNew ? 'col' : 'col-lg-4'">
          <div class="row row-cards row-deck h-100">

            <div class="col-12">
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title">Delivery</h3>
                </div>
                <div class="card-body">
                  <div class="form-row">
                    <div class="form-group col-lg-6">
                      <label class="form-label">Transporter</label>
                      <div class="input-group">
                        {{item.transporter}}
                      </div>
                    </div>
                    <div v-if="item.box_sizes.length" class="form-group col-lg-6">
                      <label class="form-label">Boxes</label>
                      <div class="input-group" data-test="label-boxes">
                        {{item.box_sizes.length}}
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="form-label">Date</label>
                    <div class="input-group">
                      <input
                        v-model="deliveryDate"
                        type="date"
                        class="form-control"
                        required>
                      <div class="input-group-append">
                        <span class="input-group-text">{{moment(deliveryDate).format('ddd')}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="form-label">Time</label>
                    <select
                      v-model="deliveryTime"
                      class="form-control custom-select"
                      required>
                      <option :value="null" disabled>Select time</option>
                      <option
                        v-for="value in deliveryTimes"
                        :key="value.id"
                        :value="value">
                        {{value.name}}
                      </option>
                    </select>
                  </div>
                  <div v-if="!isNew" class="form-group">
                    <label class="form-label">Address</label>
                    <input
                      v-model="item.deliveryAddress"
                      class="form-control"
                      type="text"
                      ignore-disable
                      force-disable
                      :disabled="disableForm"
                    />
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="col-12">
          <div v-if="product" class="card">
            <div class="d-flex">
              <recipe-selection-card
                :class="[{ 'pulse-limit': recipesChanged }]"
                caption="Dinner Recipe selection"
                :disabled="totalRecipes >= product.min_recipes"
                :items="selectableRecipes"
                :max="product.min_recipes"
                :number_of_people="product.number_of_people"
                :read-only="!recipesEditable"
                :selections="item.recipes"
                :total-selected="totalRecipes"
                @input="setRecipeSelection">
              </recipe-selection-card>

              <market-recipe-selection-card
                :class="[{ 'pulse-limit': marketRecipesChanged }]"
                caption="Market Recipe selection"
                :disabled="totalMarketRecipes >= MAX_MARKET_ITEMS_SELECTABLE"
                :items="selectableMarketRecipes"
                :max="MAX_MARKET_ITEMS_SELECTABLE"
                :read-only="!recipesEditable"
                :selections="item.marketRecipes"
                :total-selected="totalMarketRecipes"
                @input="setMarketRecipeSelection">
              </market-recipe-selection-card>
            </div>

            <div class="card-footer d-flex justify-content-between align-items-center">
              <div>
                <template v-if="item.allocated">Recipes have been <strong>allocated</strong> by the system.</template>
                <template v-else>Recipes have not been allocated by the system.</template>
              </div>

              <button
                class="btn btn-primary"
                data-test="btn-delivery-split"
                @click.prevent="handleDeliverySplit">
                Delivery Split
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isNew" class="row">
        <div class="col">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Receipt</h3>
            </div>
            <div class="table-responsive">
              <receipt-table
                :value="item.receipt"
                :product="product"
                class="card-table">
                <tr v-if="item.discountCode">
                  <td class="text-right" colspan="9">
                    <template v-if="item.discountCode">
                      Discount code: {{item.discountCode}}
                      ({{parsedDiscountType}})
                    </template>
                  </td>
                </tr>
              </receipt-table>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isNew" class="row">
        <div class="col">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Transactions</h3>
              <div class="card-options">
                <button
                  v-if="can(uiPermissions.PAYMENTS_CREATE)"
                  class="btn btn-primary"
                  ignore-disable
                  @click.prevent="openAddTransactionModal">
                  <i class="fe fe-list mr-2"></i>
                  Add transaction
                </button>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table card-table table-vcenter text-nowrap">
                <thead>
                  <tr>
                    <th>Transaction</th>
                    <th>Amount</th>
                    <th>Date</th>
                    <th class="text-center">Status</th>
                    <th class="text-center">Message</th>
                    <th>Reference</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="transaction in item.transactions" :key="transaction.id">
                    <td>
                      <div>
                        #{{transaction.id}}
                      </div>
                      <div class="small text-muted">{{transaction.description}}</div>
                    </td>
                    <td>
                      {{transaction.currency}} {{numeral(transaction.amount).format('0,0[.]00')}}
                    </td>
                    <td>
                      {{moment.utc(transaction.createdAt).local().format('D MMM YYYY')}}
                    </td>
                    <td class="text-center">
                      {{transaction.status}}
                    </td>
                    <td class="text-center">
                      {{transaction.message}}
                    </td>
                    <td>
                      <input
                        v-model="transaction.transactionRef"
                        class="form-control"
                        type="text"
                        @input="transaction.dirty = true"/>
                    </td>
                    <td class="text-center">
                      <div v-if="transaction.transactionType !== PaymentTypes.Refund" class="item-action dropdown position-unset">
                        <a
                          tabindex="0"
                          data-toggle="dropdown"
                          class="icon"><i class="fe fe-more-vertical"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right">
                          <button
                            v-if="can(uiPermissions.PAYMENTS_REFUND)"
                            type="button"
                            class="dropdown-item"
                            ignore-disable
                            force-disable
                            :disabled="disableRefund"
                            @click="refund(transaction)">
                            <i class="dropdown-icon fe fe-external-link"></i>
                            Refund
                          </button>
                          <button
                            v-if="can(uiPermissions.PAYMENTS_VOID)"
                            type="button"
                            class="dropdown-item"
                            @click="voidPayment(transaction)">
                            <i class="dropdown-icon fe fe-external-link"></i>
                            Void
                          </button>
                          <a
                            v-if="transaction.checkoutLink"
                            class="dropdown-item"
                            :href="transaction.checkoutLink"
                            target="_blank"
                            rel="noopener noreferrer">
                            <i class="dropdown-icon fe fe-external-link"></i>
                            Go to checkout.com
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="item.transactions.length === 0">
                    <td colspan="6">The order does not have any transactions.</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div v-if="subscription && subscription.id" class="row">
        <div class="col">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Other orders on subscription</h3>
            </div>
            <div class="table-responsive">
              <subscription-orders-table :value="subscription" class="card-table"/>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="!isNew && can(uiPermissions.COMPLAINTS_VIEW)"
        ref="complaintsRow"
        class="row">
        <div class="col">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Complaints</h3>
              <div class="card-options">
                <button
                  v-if="can(uiPermissions.COMPLAINTS_CREATE)"
                  class="btn btn-primary"
                  ignore-disable
                  force-disable
                  @click.prevent="openComplaintsModal()">
                  <i class="fe fe-flag mr-2"></i>
                  Log complaint
                </button>
              </div>
            </div>
            <table class="table card-table table-vcenter text-nowrap">
              <thead>
                <tr>
                  <th>Issue type</th>
                  <th>Resolution</th>
                  <th>Reported by</th>
                  <th>Created</th>
                  <th class="w-1"></th>
                </tr>
              </thead>
              <tr v-for="complaint in item.complaints" :key="complaint.id">
                <td>
                  <a
                    v-if="can(uiPermissions.COMPLAINTS_UPDATE)"
                    href="#"
                    @click.prevent="openComplaintsModal(complaint.id)">{{complaint.complaint.title}}
                  </a>
                  <span v-else>{{complaint.complaint.title}}</span>
                </td>
                <td>
                  {{resolutionTypes[complaint.resolution]}} <span v-if="parseFloat(complaint.resolutionAmount) > 0"> -
                    {{numeral(complaint.resolutionAmount).format('0,0.00')}}</span>
                </td>
                <td>{{(complaint.reportedBy || {}).email || 'N/A'}}</td>
                <td>{{moment(complaint.createdAt).format('ddd, DD MMM YYYY')}}</td>
                <td>
                  <div
                    v-if="can([uiPermissions.COMPLAINTS_UPDATE, uiPermissions.COMPLAINTS_DELETE])"
                    class="item-action dropdown">
                    <a
                      tabindex="0"
                      data-toggle="dropdown"
                      class="icon"><i class="fe fe-more-vertical"></i></a>
                    <div class="dropdown-menu dropdown-menu-right cursor-pointer">
                      <a
                        v-if="can(uiPermissions.COMPLAINTS_UPDATE)"
                        href="#"
                        class="dropdown-item"
                        @click.prevent="openComplaintsModal(complaint.id)">
                        <i class="dropdown-icon fe fe-edit"></i> Edit
                      </a>
                      <div class="dropdown-divider"></div>
                      <button
                        v-if="can(uiPermissions.COMPLAINTS_DELETE)"
                        type="button"
                        class="dropdown-item"
                        ignore-disable
                        @click="handleDeleteComplaint(complaint)">
                        <i class="dropdown-icon fe fe-trash"></i> Delete
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
              <tr v-if="!item.complaints.length">
                <td colspan="5" class="text-center">The order does not have complaints</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div
        v-if="!isNew && can(uiPermissions.ORDER_CHANGE_REQUESTS_VIEW)"
        ref="changeRequestRow"
        class="row">
        <div class="col">
          <change-requests
            v-if="deliveryTime"
            ref="changeRequestRef"
            :delivery-date="deliveryDate"
            :delivery-time="deliveryTime"
            :delivery-days="deliveryDays"/>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <edit-footer
            :dirty="dirty"
            entity="order"
            :isNew="isNew"
            :submitting="submitting"
            ignore-disable
            force-disable
            :disabled="disableForm"
            :submit-title="disableForm ? 'Cannot save - Past cut off' : 'Save Order'"
            hide-delete="true">
            <button
              v-if="!isNew && can(uiPermissions.ORDERS_INVOICE_SEND)"
              type="button"
              class="btn btn-secondary mr-2"
              ignore-disable
              @click="sendInvoice">
              Send invoice
            </button>
          </edit-footer>
        </div>
      </div>
    </form>

    <complaint-modal
      v-if="item.id"
      :show.sync="complaintModal"
      :order="item"
      :market-recipes="selectedMarketRecipes"
      :cid.sync="complaintId"
      :repeat-offender="repeatOffender"
      @success="handleOnComplaintSuccess">
    </complaint-modal>

    <add-transaction-modal
      v-if="item.id"
      :show.sync="addTransactionModal"
      :orderId="item.id"
      @onSuccess="handleOnTransactionSuccess"/>

    <delivery-split-modal
      v-if="item.id && weeklyMenu"
      :show.sync="deliverySplitModal"
      :subscriptionId="subscription.id"
      :weeklyMenuId="weeklyMenu.id"
      :startDate="weeklyMenu.startDate"
      :recipes="selectedRecipes"
      :marketRecipes="selectedMarketRecipes"
      @onSuccess="reset"/>
  </div>
</template>

<script>
import ComplaintModal from '@/components/ComplaintModal';
import EditFooter from '@/components/EditFooter';
import EditHeader from '@/components/EditHeader';
import ProductSelector from '@/components/ProductSelector';
import ReceiptTable from '@/components/ReceiptTable';
import RecipeSelectionCard from '@/components/RecipeSelectionCard';
import MarketRecipeSelectionCard from '@/components/MarketRecipeSelectionCard.vue';
import SubscriptionOrdersTable from '@/components/SubscriptionOrdersTable';
import UserCard from '@/components/UserCard';
import UserSelector from '@/components/UserSelector';
import edit from '@/mixins/edit';
import {deliveryCities, orderComplaints, orders, payments, products, repeatOffenders, subscriptions, weeklyMenusFind, market} from '@/services';
import {pastCutOffTime} from '@/utils';
import IssueTypes from '@hellochef/shared-js/enums/IssueTypes';
import OrderStatuses from '@hellochef/shared-js/enums/OrderStatuses';
import PaymentMethods from '@hellochef/shared-js/enums/PaymentMethods';
import PaymentStatuses from '@hellochef/shared-js/enums/PaymentStatuses';
import PaymentTypes from '@hellochef/shared-js/enums/PaymentTypes';
import Permissions from '@hellochef/shared-js/enums/Permissions';
import ResolutionTypes from '@hellochef/shared-js/enums/ResolutionTypes';
import {thursdayBasedWeek} from '@hellochef/shared-js/helpers';
import Recipes from '@hellochef/shared-js/services/Recipes';
import moment from 'moment-timezone';
import ChangeRequests from './components/ChangeRequests.vue';
import AddTransactionModal from './components/AddTransactionModal.vue';
import {MAX_MARKET_ITEMS_SELECTABLE} from '@/constants/max-market-items-selectable';
import DeliverySplitModal from '@/components/DeliverySplitModal.vue';

const MAX_NO_ORDER_CANCELLATION_FOR_REPEAT_OFFENDER = 3;

export default {
  name: 'OrderEdit',
  components: {
    ComplaintModal,
    EditFooter,
    EditHeader,
    ReceiptTable,
    RecipeSelectionCard,
    MarketRecipeSelectionCard,
    SubscriptionOrdersTable,
    UserCard,
    UserSelector,
    ProductSelector,
    ChangeRequests,
    AddTransactionModal,
    DeliverySplitModal,
  },
  mixins: [
    edit,
  ],
  data() {
    return {
      OrderStatuses,
      PaymentMethods,
      PaymentTypes,
      MAX_MARKET_ITEMS_SELECTABLE,
      complaintId: null,
      complaintModal: false,
      deliveryCities: [],
      issueTypes: [],
      item: {
        allocated: false,
        complaints: [],
        customProduct: null,
        deliveryAddress: '',
        deliveryDate: moment().add(1, 'day'),
        deliveryTime: null,
        item: this.getSubscriptionDefaults(),
        note: '',
        paymentMethod: PaymentMethods.Free,
        productName: '',
        receipt: {},
        recipes: [],
        marketRecipes: [],
        status: OrderStatuses.Created,
        transactions: [],
        discountType: '',
        discountCode: '',
        box_sizes: [],
      },
      manualPaymentSubmitted: false,
      newCustomer: null,
      paymentRefundedSubmitted: false,
      paymentVoidedFailed: false,
      paymentVoidedSubmitted: false,
      products: [],
      resolutionTypes: [],
      sendInvoiceError: false,
      sendInvoiceSubmitted: false,
      successComplaint: false,
      weeklyMenu: null,
      marketSchedule: null,
      recipesChanged: false,
      marketRecipesChanged: false,
      repeatOffender: null,
      addTransactionModal: false,
      deliverySplitModal: false,
    };
  },
  computed: {
    totalRecipes() {
      return this.item.recipes.reduce((acc, data) => {
        acc = acc + data?.quantity;
        return acc;
      }, 0);
    },
    totalMarketRecipes() {
      return this.item.marketRecipes.reduce((acc, data) => {
        acc = acc + data?.quantity;
        return acc;
      }, 0);
    },
    disableForm() {
      return (pastCutOffTime(this.item.deliveryDate) && !this.isNew) || !this.can(Permissions.ORDERS_UPDATE);
    },
    parsedDiscountType() {
      const types = this.item?.discountType?.split('\\') || [];
      return types.length ? types[types.length - 1] : '';
    },
    // this solely depends on the item productName e.g. choice-2-3 as subscription shouldn't be changed to manually override the specific order product
    customProduct: {
      get() {
        const product = this.products.find(product => product.name === `product-${this.item?.productName}`) || this.products.find(product => product.name === this.item?.productName) || null;
        return product;
      },
      set(newValue) {
        this.item.recipes = [];
        this.item.productName = newValue?.name;
        this.item.customProduct = newValue;
      },
    },
    customer: {
      get() {
        return this.isNew ? this.newCustomer : this.subscription.customer;
      },
      set(newValue) {
        this.isNew ? (this.newCustomer = newValue) : (this.subscription.customer = newValue);
        this.subscription = this.getSubscriptionDefaults();
      },
    },
    deliveryCity() {
      return this.customer ? this.deliveryCities.find(x => x.name === this.customer.deliveryAddress.city) : null;
    },
    deliveryDate: {
      get() { return this.item.deliveryDate.format('YYYY-MM-DD'); },
      set(newValue) {
        if (thursdayBasedWeek(this.item.deliveryDate).format('YYYY-MM-DD') !== thursdayBasedWeek(moment(newValue)).format('YYYY-MM-DD')) {
          this.item.recipes = [];
          this.recipesChanged = true;

          this.item.marketRecipes = [];
          this.marketRecipesChanged = true;
        }
        this.item.deliveryDate = moment(newValue);
      },
    },
    deliveryDay() {
      return this.deliveryDate ? this.deliveryDays.find(x => x.name === moment(this.deliveryDate).format('dddd')) : null;
    },
    deliveryDays() {
      return this.deliveryCity ? this.deliveryCity.days : [];
    },
    deliveryTime: {
      get() { return this.item.deliveryTime ? this.deliveryTimes.find(x => x.id === this.item.deliveryTime.id) : null; },
      set(newValue) { this.item.deliveryTime = newValue; },
    },
    deliveryTimes() {
      return this.deliveryDay ? this.deliveryDays.find(x => x.id === this.deliveryDay.id).times : [];
    },
    product() {
      return this.customProduct || this.subscription?.product || null;
    },
    recipesEditable() {
      // The admin panel doesn't impose limits on cut-off date, etc. for admins. But the weekly menu is required for edits to be identifiable.
      return !!this.weeklyMenu;
    },
    route() {
      return `/orders/${this.item.id}`;
    },
    selectableRecipes() {
      return this.weeklyMenu ? this.weeklyMenu.recipes : this.item.recipes.map(recipe => ({number: recipe.number, recipe}));
    },
    selectableMarketRecipes() {
      return this.marketSchedule ?? this.item.marketRecipes;
    },
    subscription: {
      get() { return (!this.isNew || this.item.item.id) ? this.item.item : null; },
      set(newValue) {
        this.item.item = newValue;
        this.customProduct = newValue.product;
      },
    },
    filterProducts() {
      return this.products;
    },
    repeatOffenderMessage() {
      const noOfCancellation = this.repeatOffender?.no_of_cancellations;

      if (!noOfCancellation || noOfCancellation < MAX_NO_ORDER_CANCELLATION_FOR_REPEAT_OFFENDER) return '';

      // for MAX_NO_ORDER_CANCELLATION_FOR_REPEAT_OFFENDER cancellations in 3 months
      if (noOfCancellation === MAX_NO_ORDER_CANCELLATION_FOR_REPEAT_OFFENDER) {
        return `This customer has cancelled ${MAX_NO_ORDER_CANCELLATION_FOR_REPEAT_OFFENDER} times in the past 3 months. Do not issue a refund on their next cancellation.`;
      }

      // for more than 3 cancellations in 3 months
      return `This customer has cancelled ${noOfCancellation} times in the past 3 months. Do not issue a refund.`;
    },
    disableRefund() {
      const noOfCancellation = this.repeatOffender?.no_of_cancellations;
      return noOfCancellation >= MAX_NO_ORDER_CANCELLATION_FOR_REPEAT_OFFENDER || this.disableForm;
    },
    selectedMarketRecipes() {
      const expandedRecipes = [];

      this.selectableMarketRecipes.forEach(recipe => {
        const selected = this.item.marketRecipes.find(selected => selected.recipe_id === recipe.id);
        if (!selected || !selected.quantity) return;

        expandedRecipes.push({
          recipe_id: recipe.id,
          name: recipe.name,
          quantity: selected.quantity,
          type: recipe.type,
          delivery_split_number: selected.delivery_split_number ?? 1,
        });
      });

      return expandedRecipes;
    },
    selectedRecipes() {
      return this.item.recipes.map(recipe => ({
        recipe_id: recipe.id,
        name: recipe.name,
        quantity: recipe.quantity,
        delivery_split_number: recipe.delivery_split_number ?? 1,
      }));
    },
  },
  watch: {
    'item.deliveryDate'() {
      if (this.item.deliveryDate) {
        this.fetchWeeklyMenu(this.item.deliveryDate);
        this.fetchMarketSchedule(this.item.deliveryDate);
      }
      else { this.weeklyMenu = null; }
    },
  },
  created() {
    deliveryCities.getByParameters().then(result => this.deliveryCities = result.items);
    products.getByParameters({column: 'name', direction: 'asc', limit: 15, with_classic: true}).then(result => this.products = result.items);

    if (this.isNew) {
      this.fetchWeeklyMenu(this.item.deliveryDate);
      this.fetchMarketSchedule(this.item.deliveryDate);
    }

    this.issueTypes = IssueTypes;
    this.resolutionTypes = ResolutionTypes;

    if (this.$route.query.complaint) { this.complaintModal = true; }
  },
  methods: {
    fetchData(id) {
      // check if the customer is a repeat offender
      repeatOffenders.getByParameters({order_id: id})
        .then(result => {
          this.repeatOffender = result.repeatOffender;
        });

      // fetch order change requests on routing to different order from same page
      // initially the order change request will be loaded from the component itself and the ref will be undefined here
      // so there won't be any duplicate requests
      this.$refs.changeRequestRef?.refresh();

      return orders.getById(id, {params: {with: 'complaints.complaint.category'}});
    },
    fetchWeeklyMenu(startDate) {
      weeklyMenusFind.getByParameters({
        by: 'startDate',
        value: thursdayBasedWeek(startDate).format('YYYY-MM-DD'),
      })
        .then(({weeklyMenu}) => {
          const recipes = Recipes.transformToRecipeSwapFormat(weeklyMenu.recipes);
          this.weeklyMenu = Object.assign(weeklyMenu, {recipes});
        });
    },
    async fetchMarketSchedule(startDate) {
      try {
        const formattedStartDate = thursdayBasedWeek(startDate).format('YYYY-MM-DD');
        const {recipes} = await market.getScheduleByWeek(formattedStartDate);
        this.marketSchedule = recipes || [];
      }
      catch (error) {
        console.error(error);
      }
    },
    getSubscriptionDefaults() {
      return {

        customer: {
          billingAddress: {},
          deliveryAddress: {},
          user: {},
        },
        product: {
          menu: {},
        },
      };
    },
    setRecipeSelection(data) {
      this.recipesChanged = false;
      const selected = this.item.recipes.find(recipe => recipe.id === data.id);
      if (selected) {
        return Object.assign(selected, {quantity: data.quantity});
      }
      return this.item.recipes.push(data);
    },
    setMarketRecipeSelection(data) {
      this.marketRecipesChanged = false;
      const selected = this.item.marketRecipes.find(recipe => recipe.recipe_id === data.recipe_id);
      if (selected) {
        return Object.assign(selected, {quantity: data.quantity});
      }
      return this.item.marketRecipes.push(data);
    },
    async handleDeleteComplaint(item) {
      if (window.confirm('Deleting a complaint cannot be undone. Are you sure that you want to delete this complaint?')) {
        await orderComplaints.deleteById(item.id);
        this.reset();
      }
    },
    async manual(order) {
      if (this.disableForm) {
        return alert('Cannot save orders after cut off time');
      }

      if (window.confirm('Are you sure that you want to process a manual payment?')) {
        const {item: payment} = await payments.manualPayment(order.id);
        const tIndex = this.item.transactions.findIndex(val => val.id === payment.id);

        this.manualPaymentSubmitted = true;

        if (tIndex > -1) { return this.item.transactions[tIndex].message = payment.message; }

        return this.item.transactions.push(payment);
      }
    },
    openComplaintsModal(id) {
      this.complaintId = id || null;
      this.complaintModal = true;
    },
    paymentStatus(transaction) {
      return Object.keys(PaymentStatuses).find(key => PaymentStatuses[key] === transaction.status);
    },
    async refund(transaction) {
      if (this.disableForm) {
        return alert('Cannot save orders after cut off time');
      }

      if (transaction.description === 'Dessert Surcharge') {
        return alert('Refund for dessert surcharge is not allowed here. Please refund through the checkout portal and cancel the change request.');
      }

      const noOfCancellation = this.repeatOffender?.no_of_cancellations;
      if (noOfCancellation > 3) {
        return alert(`This customer has cancelled ${noOfCancellation} times in the past 4 months. Do not issue a refund.`);
      }

      if (window.confirm('Are you sure to refund this order?')) {
        const {item: payment} = await payments.refundById(transaction.id);
        const tIndex = this.item.transactions.findIndex(val => val.id === payment.id);

        this.paymentRefundedSubmitted = true;

        if (tIndex > -1) { return this.item.transactions[tIndex].message = payment.message; }

        return this.item.transactions.push(payment);
      }
    },
    scrollToComplaints() {
      window.scrollTo({behavior: 'smooth', top: this.$refs.complaintsRow.getBoundingClientRect().top});
    },
    async sendInvoice() {
      this.sendInvoiceError = false;
      this.sendInvoiceSubmitted = false;

      try {
        await orders.sendInvoiceById(this.item.id);
        this.sendInvoiceSubmitted = true;
      }
      catch (ex) {
        this.sendInvoiceError = true;
        throw ex;
      }
      finally {
        window.scrollTo(0, 0);
      }
    },
    validate() {
      return this.totalRecipes >= this.product.min_recipes;
    },
    async submitData(item) {
      if (this.disableForm) {
        return alert('Cannot save orders after cut off time');
      }

      if (!this.isNew) {
        const payload = Object.assign({}, {
          id: item.id,
          status: item.status,
          deliveryAddress: item.deliveryAddress,
          deliveryDate: item.deliveryDate.format('YYYY-MM-DD'),
          deliveryDay: this.deliveryDay.id,
          deliveryTime: item.deliveryTime.id,
          item: item.item.id,
          paymentMethod: item.paymentMethod,
          note: item.note,
          recipes: item.recipes.filter(x => x.quantity).map(x => ({recipe_id: x.id, quantity: x.quantity})),
          marketRecipes: item.marketRecipes.map(x => ({recipe_id: x.recipe_id, quantity: x.quantity})),
          product: this.customProduct?.id || this.item.item.product?.id,
          type: 'subscription',
          with: 'complaints',
        });

        // if update order use replace endpoint
        const result = await orders.replace(payload);

        return {item: result.data.data.order};
      }

      // keep this after edit order logic
      if (this.recipesEditable) {
        await subscriptions.updateSelectionsById(item.item.id, {
          recipes: item.recipes.filter(x => x.quantity).map(x => ({recipe_id: x.id, quantity: x.quantity})),
          startDate: thursdayBasedWeek(item.deliveryDate).format('YYYY-MM-DD'),
        }).catch(err => {
          this.error = true;
          console.error(err);
        });

        if (item.marketRecipes?.length && this.weeklyMenu && this.subscription) {
          try {
            const body = {
              subscription_id: this.subscription.id,
              weekly_menu_id: this.weeklyMenu.id,
              selections: item.marketRecipes.map(selection => ({
                recipe_id: selection.recipe_id,
                quantity: selection.quantity,
              })),
            };
            await market.updateSelections(body);
          }
          catch (error) {
            this.error = true;
            console.error(error);
          }
        }
      }

      // else do the usual
      // TODO: implementation will change over here as soon is BE is refactor
      const payload = Object.assign({}, item, {
        currency: 'AED',
        deliveryAddress: item.deliveryAddress,
        deliveryDate: item.deliveryDate.format('YYYY-MM-DD'),
        deliveryDay: this.deliveryDay.id,
        deliveryTime: item.deliveryTime.id,
        item: item.item.id,
        product: this.item?.customProduct?.id || null,
        type: 'subscription',
        with: 'complaints',
      });

      delete payload.customProduct;
      delete payload.receipt;
      delete payload.recipes;
      delete payload.transactions;

      const result = await orders.saveOrUpdate(payload);

      const promises = [];

      item.transactions.forEach(data => {
        if (data.dirty) { promises.push(payments.updateById(data.id, data)); }
      });

      if (promises.length) { await Promise.all(promises); }

      return result;
    },
    transformData(result) {
      result.item.deliveryDate = moment(result.item.deliveryDate);
      result.item.complaints = result.item?.complaints ? result.item?.complaints.sort((first, second) => parseFloat(second.id) - parseFloat(first.id)) : this.item.complaints || [];
      result.item.box_sizes = result.item?.box_sizes || [];

      return result;
    },
    async voidPayment(transaction) {
      if (this.disableForm) {
        return alert('Cannot save orders after cut off time');
      }

      if (transaction.description === 'Dessert Surcharge') {
        return alert('Void payment for dessert surcharge is not allowed here. Please refund through the checkout portal and cancel the change request.');
      }

      if (window.confirm('Are you sure to void this payment?')) {
        try {
          await payments.voidById(transaction.id);
          this.paymentVoidedSubmitted = true;

          this.reset();
        }
        catch (e) {
          this.paymentVoidedFailed = true;
          throw e;
        }
        finally {
          window.scrollTo(0, 0);
        }
      }
    },
    handleOnComplaintSuccess() {
      this.successComplaint = true;
      this.reset();
      this.$refs.changeRequestRef.refresh();
    },
    openAddTransactionModal() {
      this.addTransactionModal = true;
    },
    handleOnTransactionSuccess(transaction) {
      this.addTransactionModal = false;
      this.item.transactions.push(transaction);
    },
    handleDeliverySplit() {
      if (this.dirty) {
        this.$toasted.error('Please save the order before making changes to the delivery split');
        return;
      }
      this.deliverySplitModal = true;
    },
  },
};
</script>
