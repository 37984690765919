<template>
  <b-modal
    v-model="safeShow"
    title="Split delivery"
    size="lg"
    :scrollable="true"
    modal-class="modal-split-delivery"
    id="modal-split-delivery">
    <template slot="modal-header-close"><wbr/></template>

    <template slot="modal-footer">
      <button
        class="btn btn-primary w-9"
        type="submit"
        form="splitDeliveryForm"
        :disabled="loading">
        Submit
      </button>
    </template>

    <div
      v-show="invalidMessage"
      class="alert alert-icon alert-danger alert-dismissible"
      data-test="label-invalid-message">
      <button
        type="button"
        class="close"
        @click="invalidMessage = ''">
      </button>
      <i class="fe fe-alert-triangle mr-2" aria-hidden="true"></i>
      {{invalidMessage}}
    </div>

    <form
      ref="splitDeliveryForm"
      id="splitDeliveryForm"
      @submit.prevent="submit">
      <dimmer :active="loading">
        <table class="table table-vcenter card-table">
          <thead>
            <tr>
              <th>Delivery</th>
              <th class="text-nowrap">Recipe Id</th>
              <th class="text-center">Recipe Name</th>
              <th class="text-center">Quantity</th>
            </tr>
          </thead>
          <tr v-for="item in selectedRecipes" :key="item.recipe_id">
            <td>
              <select
                v-model="item.delivery_split_number"
                class="form-control custom-select"
                required>
                <option value="" disabled>Select delivery</option>
                <option
                  v-for="delivery in DeliverySplits"
                  :key="delivery.id"
                  :value="delivery.id">
                  {{delivery.label}}
                </option>
              </select>
            </td>
            <td>
              {{item.recipe_id}}
            </td>
            <td>
              <div class="d-flex gap-8">
                {{item.name}}
                <h-badge v-if="item.type" class="type-badge">
                  {{item.type}}
                </h-badge>
              </div>
            </td>
            <td class="text-center">
              {{item.quantity}}
            </td>
          </tr>
        </table>
      </dimmer>
    </form>
  </b-modal>
</template>

<script>
import {captureException} from '@sentry/vue';
import HBadge from '@/components/ui/HBadge';
import {subscriptions, market} from '@/services';
import cloneDeep from 'lodash/cloneDeep';

const DeliverySplits = [
  {
    id: 1,
    label: 'Delivery 1',
  },
  {
    id: 2,
    label: 'Delivery 2',
  },
];

export default {
  name: 'AddTransactionModal',
  components: {
    HBadge,
  },
  props: [
    'recipes',
    'marketRecipes',
    'show',
    'startDate',
    'weeklyMenuId',
    'subscriptionId',
  ],
  data() {
    return {
      loading: false,
      invalidMessage: '',
      selectedRecipes: [],
      DeliverySplits,
    };
  },
  computed: {
    safeShow: {
      get() { return this.show; },
      set(newValue) {
        this.$emit('update:show', newValue);
      },
    },
  },
  watch: {
    show() {
      const recipes = cloneDeep(this.recipes);
      const marketRecipes = cloneDeep(this.marketRecipes);
      this.selectedRecipes = recipes.concat(marketRecipes);
    },
  },
  methods: {
    async submit() {
      try {
        this.loading = true;
        const recipes = this.selectedRecipes.filter(it => !it.type);
        const marketRecipes = this.selectedRecipes.filter(it => it.type);

        const updateSelections = subscriptions.updateSelectionsById(this.subscriptionId, {
          recipes: recipes.map(it => ({
            recipe_id: it.recipe_id,
            quantity: it.quantity,
            delivery_split_number: it.delivery_split_number,
          })),
          startDate: this.startDate,
        });

        const updateMarketSelections = market.updateSelections({
          subscription_id: this.subscriptionId,
          weekly_menu_id: this.weeklyMenuId,
          selections: marketRecipes.map(it => ({
            recipe_id: it.recipe_id,
            quantity: it.quantity,
            delivery_split_number: it.delivery_split_number,
          })),
        });

        await Promise.all([updateSelections, updateMarketSelections]);

        this.$emit('onSuccess');
        this.safeShow = false;
      }
      catch (error) {
        captureException(error);
        if (error.response?.data.message) {
          this.invalidMessage = error.response.data.message;
        }
      }
      finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
.modal-footer {
  justify-content: end;
}

.type-badge {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 6px;
  gap: 2px;

  height: 24px;

  background-color: #E8ECEF !important;
  border: none !important;
  border-radius: 4px;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #4A5157 !important;
}

.modal-split-delivery .modal-body {
  max-height: 70vh;
  overflow-y: auto;
  scroll-behavior: smooth;
  padding: 0;
}
</style>
